import { Component, Input, forwardRef, Renderer2, ViewChild, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


const noop = () => {
};

export const RADIO_BUTTON_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonComponent),
    multi: true,
};

@Component({
    selector: 'radio-button',
    providers: [RADIO_BUTTON_VALUE_ACCESSOR],

    //   template: `
    //      <label #radiobutton tabindex="0" [ngClass]="{active: text == value}" (click)="onClick" role="button">{{text}}</label>

    //    `,
    template: `
    <div class="btn-group">
        <div class="btn-group-toggle" data-toggle="buttons" >
            <label class="btn" [className]="option.value == selectedValue ? option.activeClass : 'btn btn-default'" *ngFor="let option of options" [ngClass]="{'active': option.value == selectedValue ,'disabled':disabled , 'hide' : option.value != selectedValue && disabled  }" (click)="changeValue(option.value)" >
                <input  class="form-control input-sm" type="radio" (id)="id" [(ngModel)]="selectedValue"> {{option.value}}
            </label>
        </div>
    </div>
              `,
})

export class RadioButtonComponent implements ControlValueAccessor, OnInit {

    @Input() id: string;
    @Input() options: any[];
    @Input() disabled: boolean;
    _selectedValue: string;

    get selectedValue(): any {
        return this._selectedValue;
    };

    set selectedValue(v: any) {
        if (v !== this._selectedValue) {
            this._selectedValue = v;
            this.onChangeCallback(v);
        }
    }

    changeValue(option) {
        console.log('option', option, 'this.disabled', this.disabled);
        if (!this.disabled) this.selectedValue = option;
        return false;
    }

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    writeValue(value: any): void {
        if (this._selectedValue !== value) {
            this._selectedValue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    constructor(private renderer: Renderer2) {

    }
    ngOnInit() {

    }


}