import {
    Component,
    Input,
    Output,
    ContentChildren,
    HostListener,
    EventEmitter,
    OnChanges , SimpleChanges, ViewChild
  } from '@angular/core';
  
  import { TabComponent } from './tab.component';
  
  @Component({
    selector: 'tabset',
    template: `
   
    <div class="tabbed tabbed-content-control tabbed-side-nav box-container box-shadow clearfix">
        <div class="filter-bar tab-filter-bar container-fluid font-gotham match-height">
            <div class="row centered width-600">
                <div class="col col-filter-by col-xs-12 centered-xs">
                    <div class="dropdown">
                        <button id="tabbedSideNavFilter" class="dropdown-button relative-fix visible-xs" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="selected-text"></span>
                            <span class="glyphicon glyphicon-chevron-down pull-right"></span>
                        </button>
                        <div class="dropdown-menu box-shadow" aria-labelledby="opcoFilter">
                            <ul class="tabs nav nav-tabs font-gotham" role="tablist">
                                <li *ngFor="let tab of tabs" role="presentation"  [class.active]="tab.active">
                                <a (click)="tabClicked(tab)" [class.disabled]="tab.disabled" role="tab" data-toggle="tab">{{tab.title}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        
        <div class="tab-content match-height">
            <ng-content></ng-content>
        </div>

        
        </div>
    `
   
  })
  export class TabsetComponent  {
  
    @Input() vertical;
    @Output() onSelect = new EventEmitter();
    @ContentChildren(TabComponent) tabs;

    selectedIndex:number;
    ngAfterContentInit() {

      const tabs = this.tabs.toArray();
      const actives = this.tabs.filter(t => { return t.active });
  
        
      if(actives.length > 1) {
        console.error(`Multiple active tabs set 'active'`);
      } else if(!actives.length && tabs.length) {
        tabs[0].active = true;
        this.selectedIndex = 0;
      
      }
    }

    nextTab(){
        if(this.selectedIndex + 1 > this.tabs.toArray().length - 1) return;
        this.selectedIndex++;
        this.tabClicked(this.tabs.toArray()[this.selectedIndex]);
    }

    previousTab(){
        if(this.selectedIndex - 1 < 0) return;
        this.selectedIndex--;
        this.tabClicked(this.tabs.toArray()[this.selectedIndex]);
    }

    tabClicked(tab) {
      const tabs = this.tabs.toArray();
  
      tabs.forEach(tab => tab.active = false);
      tab.active = true;
      this.selectedIndex = tabs.indexOf(tab);
      window.scrollTo(0,0);
      this.onSelect.emit(tab);
    }
  
  }
  