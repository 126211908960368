import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CanDeactivateGuard } from './shared/components/can-deactivate/can-deactivate.guard';
import { UnauthorizedPageComponent } from './shared/components/unauthorized-page/unauthorized-page.component';
//import { AppPermissionGuard } from './app-permission.guard';
import { UnauthorizedApplicationComponent } from './shared/components/unauthorized-application/unauthorized-application.component';
import { MsalGuard } from '@azure/msal-angular';



const routes: Routes = [

  //{ path: '', loadChildren: './pages/home/home.module#HomeModule'},
  { path: '', loadChildren: () => import('./pages/home/home.module').then(x => x.HomeModule), canActivate: [MsalGuard] },
  //{ path: 'home', loadChildren: './pages/home/home.module#HomeModule'},
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(x => x.HomeModule),canActivate: [MsalGuard] },


  //{ path: 'search-material', loadChildren: './pages/search-material/search-material.module#SearchMaterialModule'},
  { path: 'search-material', loadChildren: () => import('./pages/search-material/search-material.module').then(x => x.SearchMaterialModule) },
  //{ path: 'multiple-items-search', loadChildren: './pages/multiple-items-search/multiple-items-search.module#MultipleItemsSearchModule'},
  { path: 'multiple-items-search', loadChildren: () => import('./pages/multiple-items-search/multiple-items-search.module').then(x => x.MultipleItemsSearchModule) },
  //{ path: 'request-new-jde', loadChildren: './pages/request-new-jde/request-new-jde.module#RequestNewJdeModule'},
  { path: 'request-new-jde', loadChildren: () => import('./pages/request-new-jde/request-new-jde.module').then(x => x.RequestNewJdeModule) },

  //{ path: 'search-mto', loadChildren: './pages/search-mto/search-mto.module#SearchMTOModule'},
  { path: 'search-mto', loadChildren: () => import('./pages/search-mto/search-mto.module').then(x => x.SearchMTOModule) },

  //{ path: 'create-mto/:id', loadChildren: './pages/create-mto/create-mto.module#CreateMtoModule', canDeactivate: [CanDeactivateGuard]},
  { path: 'create-mto/:id', loadChildren: () => import('./pages/create-mto/create-mto.module').then(x => x.CreateMtoModule), canDeactivate: [CanDeactivateGuard] },
  //{ path: 'create-mto/:id/:action', loadChildren: './pages/create-mto/create-mto.module#CreateMtoModule', canDeactivate: [CanDeactivateGuard]},
  { path: 'create-mto/:id/:action', loadChildren: () => import('./pages/create-mto/create-mto.module').then(x => x.CreateMtoModule), canDeactivate: [CanDeactivateGuard] },
  //{ path: 'create-mto', loadChildren: './pages/create-mto/create-mto.module#CreateMtoModule', canDeactivate: [CanDeactivateGuard]},
  { path: 'create-mto', loadChildren: () => import('./pages/create-mto/create-mto.module').then(x => x.CreateMtoModule), canDeactivate: [CanDeactivateGuard] },

  //{ path: 'master', loadChildren: './pages/master/master.module#MasterModule' },
  { path: 'master', loadChildren: () => import('./pages/master/master.module').then(x => x.MasterModule) },

  //{ path: 'setup-user', loadChildren: './pages/master/setup-user/setup-user.module#SetupUserModule'},
  //{ path: 'setup-role', loadChildren: './pages/master/setup-role/setup-role.module#SetupRoleModule'},
  //{ path: 'supplier-material-management', loadChildren: './pages/master/supplier-material-management/supplier-material-management.module#SupplierMaterialManagementModule'},
  //{ path: 'supplier-classification', loadChildren: './pages/master/supplier-classification/supplier.classification.module#SupplierClassificationModule'},
  //{ path: 'setup-mapping', loadChildren: './pages/master/setup-mapping/setup-mapping.module#SetupMappingModule'},
  // { path: 'setup-shipto', loadChildren: './pages/master/setup-ship-to/setup.ship.to.module#SetupShipToModule'},


  //{ path: 'suggest-mto', loadChildren: './pages/suggest-mto/suggest-mto.module#SuggestMTOModule'},
  { path: 'suggest-mto', loadChildren: () => import('./pages/suggest-mto/suggest-mto.model').then(x => x.LineItemMTO) },
  //{ path: 'material-transfer-approval', loadChildren: './pages/material-transfer-approval/material-transfer-approval.module#MaterialTransferApprovalModule'},
  { path: 'material-transfer-approval', loadChildren: () => import('./pages/material-transfer-approval/material-transfer-approval.module').then(x => x.MaterialTransferApprovalModule) },


  //{ path: 'material-reserve-form', loadChildren: './pages/material-reserve-form/material-reserve-form.module#MaterialReserveFormModule'},
  { path: 'material-reserve-form', loadChildren: () => import('./pages/material-reserve-form/material-reserve-form.module').then(x => x.MaterialReserveFormModule) },
  //{ path: 'material-reserve-form/:date', loadChildren: './pages/material-reserve-form/material-reserve-form.module#MaterialReserveFormModule'},
  { path: 'material-reserve-form/:date', loadChildren: () => import('./pages/material-reserve-form/material-reserve-form.module').then(x => x.MaterialReserveFormModule) },
  //{ path: 'material-call-off/:mconumber', loadChildren: './pages/material-call-off/material-call-off.module#MaterialCallOffModule'},
  { path: 'material-call-off/:mconumber', loadChildren: () => import('./pages/material-call-off/material-call-off.module').then(x => x.MaterialCallOffModule) },
  //{ path: 'material-call-off/:fabjobnumber/:mconumber', loadChildren: './pages/material-call-off/material-call-off.module#MaterialCallOffModule'},
  { path: 'material-call-off/:fabjobnumber/:mconumber', loadChildren: () => import('./pages/material-call-off/material-call-off.module').then(x => x.MaterialCallOffModule) },
  //{ path: 'material-call-off', loadChildren: './pages/material-call-off/material-call-off.module#MaterialCallOffModule'},
  { path: 'material-call-off', loadChildren: () => import('./pages/material-call-off/material-call-off.module').then(x => x.MaterialCallOffModule) },
  //{ path: 'mmp-issue-log', loadChildren: './pages/mmp-issue-log/mmp-issue-log.module#MMPIssueLogModule'},
  { path: 'mmp-issue-log', loadChildren: () => import('./pages/mmp-issue-log/mmp-issue-log.module').then(x => x.MMPIssueLogModule) },

  //{ path: 'bmc-monthly-pr', loadChildren: './pages/bmc-monthly-pr/bmc-monthly-pr.module#BMCMonthlyPRModule'},
  { path: 'bmc-monthly-pr', loadChildren: () => import('./pages/bmc-monthly-pr/bmc-monthly-pr.module').then(x => x.BMCMonthlyPRModule) },

  //{ path: 'import-pr', loadChildren: './pages/import-pr/import-pr.module#ImportPrModule'},
  { path: 'import-pr', loadChildren: () => import('./pages/import-pr/import-pr.module').then(x => x.ImportPrModule) },
  //{ path: 'assign-location', loadChildren: './pages/assign-location/assign-location.module#AssignLocationModule'},
  { path: 'assign-location', loadChildren: () => import('./pages/assign-location/assign-location.module').then(x => x.AssignLocationModule) },
  //{ path: 'assign-location/:reserveno', loadChildren: './pages/assign-location/assign-location.module#AssignLocationModule'},
  { path: 'assign-location/:reserveno', loadChildren: () => import('./pages/assign-location/assign-location.module').then(x => x.AssignLocationModule) },
  //{ path: 'current-inventory', loadChildren: './pages/current-inventory/current-inventory.module#CurrentInventoryModule'},
  { path: 'current-inventory', loadChildren: () => import('./pages/current-inventory/current-inventory.module').then(x => x.CurrentInventoryModule) },
  //{ path: 'material-transfer-relocation', loadChildren: './pages/material-transfer-re-location/material-transfer-re-location.module#MaterialTransferReLocationModule'},
  { path: 'material-transfer-relocation', loadChildren: () => import('./pages/material-transfer-re-location/material-transfer-re-location.module').then(x => x.MaterialTransferReLocationModule) },
  //{ path: 'inventory-summary', loadChildren: './pages/inventory-summary/inventory-summary.module#InventorySummaryModule'},
  { path: 'inventory-summary', loadChildren: () => import('./pages/inventory-summary/inventory-summary.module').then(x => x.InventorySummaryModule) },
  //{ path: 'inventory-summary-mmp', loadChildren: './pages/inventory-summary-mmp/inventory-summary-mmp.module#InventorySummaryMMPModule'},
  { path: 'inventory-summary-mmp', loadChildren: () => import('./pages/inventory-summary-mmp/inventory-summary-mmp.module').then(x => x.InventorySummaryMMPModule) },
  //{ path: 'material-transfer-relocation/:No', loadChildren: './pages/material-transfer-re-location/material-transfer-re-location.module#MaterialTransferReLocationModule'},
  { path: 'material-transfer-relocation/:No', loadChildren: () => import('./pages/material-transfer-re-location/material-transfer-re-location.module').then(x => x.MaterialTransferReLocationModule) },



  //{ path: 'rmr-detail/:id', loadChildren: './pages/rmr-detail/rmr-detail.module#RmrDetailModule'},
  { path: 'rmr-detail/:id', loadChildren: () => import('./pages/rmr-detail/rmr-detail.module').then(x => x.RmrDetailModule) },
  //{ path: 'rmr-detail', loadChildren: './pages/rmr-detail/rmr-detail.module#RmrDetailModule'},
  { path: 'rmr-detail', loadChildren: () => import('./pages/rmr-detail/rmr-detail.module').then(x => x.RmrDetailModule) },

  //{ path: 'search-rmr', loadChildren: './pages/search-rmr/search-rmr.module#SearchRmrModule'},
  { path: 'search-rmr', loadChildren: () => import('./pages/search-rmr/search-rmr.module').then(x => x.SearchRmrModule) },
  //{ path: 'search-rmr/:date', loadChildren: './pages/search-rmr/search-rmr.module#SearchRmrModule'},
  { path: 'search-rmr/:date', loadChildren: () => import('./pages/search-rmr/search-rmr.module').then(x => x.SearchRmrModule) },
  //{ path: 'search-rmr/:status', loadChildren: './pages/search-rmr/search-rmr.module#SearchRmrModule'},
  { path: 'search-rmr/:status', loadChildren: () => import('./pages/search-rmr/search-rmr.module').then(x => x.SearchRmrModule) },

  //{ path: 'search-for-transfer', loadChildren: './pages/search-for-transfer/search-for-transfer.module#SearchForTransferModule'},
  { path: 'search-for-transfer', loadChildren: () => import('./pages/search-for-transfer/search-for-transfer.module').then(x => x.SearchForTransferModule) },

  //{ path: 'material-warehouse-stored', loadChildren: './pages/material-warehouse-stored/material-warehouse-stored.module#MaterialWarehouseStoredModule'},
  { path: 'material-warehouse-stored', loadChildren: () => import('./pages/material-warehouse-stored/material-warehouse-stored.module').then(x => x.MaterialWarehouseStoredModule) },

  //{ path: 'rmr-re-location', loadChildren: './pages/rmr-relocation/rmr-relocation.module#RmrRelocationModule'},
  { path: 'rmr-re-location', loadChildren: () => import('./pages/rmr-relocation/rmr-relocation.module').then(x => x.RmrRelocationModule) },

  //{ path: 'material-request', loadChildren: './pages/material-request-form/material-request-form.module#MaterialRequestFormModule'},
  { path: 'material-request', loadChildren: () => import('./pages/material-request-form/material-request-form.module').then(x => x.MaterialRequestFormModule) },


  //{ path: 'export-surplus-list', loadChildren: './pages/export-surpus-material/export-surpus-material.module#ExportSurpusMaterialModule'},
  { path: 'export-surplus-list', loadChildren: () => import('./pages/export-surpus-material/export-surpus-material.module').then(x => x.ExportSurpusMaterialModule) },
  { path: 'unauthorized-application', component: UnauthorizedApplicationComponent },
  { path: 'unauthorized', component: UnauthorizedPageComponent },
  { path: '', redirectTo: '/home', pathMatch: 'prefix' },
  // { path: '**', redirectTo: '' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
