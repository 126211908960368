import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, Event, NavigationError } from '@angular/router';
import { LoggerService } from './logger.service';
import { ErrorLog } from '../models/error-log.model';

@Injectable()
export class ErrorsService {
    constructor(
        private injector: Injector,
        private router: Router,
    ) {

     
    }


    log(error) {
        // Log the error to the console
        console.error(error);
        // Send error to server
        const errorToSend = this.addContextInfo(error);
        const loggerService = this.injector.get(LoggerService);
        return loggerService.log(errorToSend);
    }

    addContextInfo(error) : ErrorLog {
        /*
        const name = error.name || null;
        const appId = 'FEMBA';

        const time = new Date().getTime();
        const id = `${appId}-${time}`;
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const status = error.status || null;
        const message = error.message || error.toString();
        const stack = error;
        const errorToSend = {name, appId, time, id, url, status, message, stack};
        return errorToSend;
        */
        let errorLog = new ErrorLog(); 
        errorLog.Name = error.name;
        errorLog.LogType = 'error';
        errorLog.Message = error.message;
        errorLog.Stack = error.stack;
        return errorLog;
    }
}