
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { NgxBootstrapConfig } from './shared/ngx-bootstrap-config/ngx-bootstrap-config';

import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AuthService } from './shared/services/auth.service';
//import { PeopleFinderComponent } from './shared/components/people-finder/people-finder.component';

import { StorageService } from './shared/services/storage.service';

import { AlertService } from './shared/services/alert.service';
import { AlertComponent } from './shared/directives/alert-shim/alert-shim.component';
import { LoadingComponent } from './shared/components/loading-page/loading-page.component';
import { LoadingService } from './shared/services/loading.service';
import { HttpService } from './shared/services/http.service';

import { AppService } from './app.service';
import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandlerService } from './shared/services/global.error-handler.service';
import { Location, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LoggerService } from './shared/services/logger.service';
import { ErrorsService } from './shared/services/errors.service';
import { CanDeactivateGuard } from './shared/components/can-deactivate/can-deactivate.guard';
//import { AppPermissionGuard } from './app-permission.guard';
//import { SelectModule } from 'ng2-select/ng2-select';
import { NgSelectModule } from '@ng-select/ng-select';


import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs'
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NavigateService } from './shared/services/navigate.service';
import { AppAuthorizedGuard } from './app-authorized.guard.';

import { JwtModule } from '@auth0/angular-jwt';
import { DataConvertInterceptor } from './shared/interceptors/data-convert.Interceptor';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './shared/factories/msal.interceptor';
import { GraphService } from './shared/services/ms-graph-client.service';
// import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
// //import { AzureADProvider } from './shared/models/auth-provider.model';
// import { environment } from 'src/environments/environment';

// const isIE =
//   window.navigator.userAgent.indexOf("MSIE ") > -1 ||
//   window.navigator.userAgent.indexOf("Trident/") > -1;
@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    //SelectModule,
    NgSelectModule,
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    JwtModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi:true},
    { provide: HTTP_INTERCEPTORS, useClass: DataConvertInterceptor, multi:true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GraphService,
    NgxBootstrapConfig,
    AuthService,
    StorageService,
    NavigateService,
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    GlobalErrorHandlerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    AlertService,
    AppService,
    ErrorsService,
    LoadingService,
    LoggerService,
    HttpService,
    CanDeactivateGuard,
    //AppPermissionGuard,
    AppAuthorizedGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
