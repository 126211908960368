export class DropDownListDTO {
    Value: string;
    Text: string;
    Key?: number;
    ValueMapping?: string[];
    ValueMappingSingle?: string;

    constructor() { }
}

export class Paginator {
    IndexPage: number;
    SizeRow: number;
    SizePagin: number;
    IsLazy: boolean;
    TotalRecordCount: number;
    constructor() {
        this.IndexPage = 1;
        this.SizeRow = 1;
        this.SizePagin = 1;
        this.IsLazy = false;
        this.TotalRecordCount = 0;
    }
}