import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from './components/chevron-tab/tab.component';
import { TabsetComponent } from './components/chevron-tab/tabset.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RadioButtonComponent } from './components/custom-form-control/radio-button';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page.component';
import { ShowErrorsComponent } from './components/show-errors/show-errors.component';
import { WordBreakPipe } from './pipes/wordbreak.pipe';
import { FileSizeStringPipe } from './pipes/filesize.pipe';
import { UploadFileCustomComponent } from './components/upload-file-custom/upload-file-custom.component';
import { NestableFormDirective } from './directives/nestable-form/nestable-form';
import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { PeopleFinderComponent, UserLookupComponent } from './components/people-finder/people-finder.component';
import { PeopleFinderService } from './components/people-finder/people-finder.service';
import { UnauthorizedApplicationComponent } from './components/unauthorized-application/unauthorized-application.component';
import { Extra_Tooltip } from './components/extra-tooltip/extra-tooltip.component';
import { RoundCheckboxComponent } from './components/round-checkbox/round-checkbox.component';
import { PadginationComponent } from './components/padgination/padgination.component';
import { UploadFileBtnComponent } from './components/upload-file-btn/upload-file-btn.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        FileUploadModule,
    ],
    declarations: [
        RadioButtonComponent,
        TabComponent,
        TabsetComponent,
        UnauthorizedPageComponent,
        UnauthorizedApplicationComponent,
        ShowErrorsComponent,
        UploadFileCustomComponent,
        NestableFormDirective,
        FileSizeStringPipe,
        WordBreakPipe,
        PeopleFinderComponent,
        UserLookupComponent,
        Extra_Tooltip,
        RoundCheckboxComponent,
        PadginationComponent,
        UploadFileBtnComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        RadioButtonComponent,
        TabComponent,
        TabsetComponent,
        UploadFileCustomComponent,
        UserLookupComponent,
        NestableFormDirective,
        Extra_Tooltip,
        RoundCheckboxComponent,
        PadginationComponent,
        UploadFileBtnComponent,
    ],
    providers: [PeopleFinderService]
})
export class SharedModule {

}
