import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

export class NavigatedData {
    url: Array<any>;
    data: any;
    extras?: NavigationExtras;
}


@Injectable()
export class NavigateService {
    private tmpData = null;
    constructor(private route: Router) { }


    public navigateByData(data: NavigatedData): Promise<boolean> {
        this.tmpData = data.data
        return this.route.navigate(data.url, data.extras);
    }

    public getNavigateData(): any {
        let data: any = this.tmpData;
        if (this.tmpData === null){
            return null
        }else if(Array.isArray(data)) {
            data = Object.assign([], data);
        } else if (typeof data === 'object') {
            data = Object.assign({}, data);
        }

        this.tmpData = null;
        return data;
    }

}
