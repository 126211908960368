import { OnInit, Component, Input, SimpleChanges } from "@angular/core";

@Component({
    selector: 'extra-tooltip',
    templateUrl: './extra-tooltip.component.html'
  })

export class Extra_Tooltip implements OnInit {
    @Input('Message') Message: string;
    @Input('Max_Length') Max_Length: number;
    
    OutputText: string;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setOutputText();
    }

    setOutputText(){
        this.OutputText = this.Message.substring(this.Max_Length, this.Message.length).trimRight();
    }
    
    
}