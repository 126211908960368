import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Paginator } from '../../models/base.model';

@Component({
  selector: 'padgination',
  templateUrl: './padgination.component.html',
  styleUrls: ['./padgination.component.css']
})
export class PadginationComponent implements OnInit {

  paginShow = 1;
  @Input() pagin: Paginator = new Paginator();
  @Input() max = 5;
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  show = [];
  constructor() { }

  ngOnInit() { }


  ngDoCheck() {
    if (this.pagin.IndexPage == 1) {
      this.paginShow = this.pagin.SizePagin;
      this.show = [];
      for (let i = 1; i <= (this.max <= this.paginShow ? this.max : this.paginShow); i++)
        this.show.push(i);
    }
  }


  public clickPage(index: number) {
    this.pagin.IndexPage = index;
    this.changePage.emit(index);
  }

  public previous() {
    if (this.pagin.IndexPage - 1 < 1) return;
    this.pagin.IndexPage -= 1;
    if (this.pagin.IndexPage % this.max == 0) {
      this.show = [];
      for (let i = (this.pagin.IndexPage - this.max) + 1; i <= this.pagin.IndexPage; i++)
        this.show.push(i);
    }
    this.changePage.emit(this.pagin.IndexPage);
  }

  public next() {
    if (this.pagin.IndexPage + 1 > this.pagin.SizePagin) return;
    this.pagin.IndexPage += 1;
    if ((this.pagin.IndexPage - 1) % (this.max) == 0) {
      this.show = [];
      for (let i = this.pagin.IndexPage; i <= (this.max + this.pagin.IndexPage) - 1 && i <= this.pagin.SizePagin; i++)
        this.show.push(i);
    }
    this.changePage.emit(this.pagin.IndexPage);
  }

}
