export const environment = {
  production: false,
  
  azureAd: {
    tenant: 'chevron.onmicrosoft.com',
    clientId: 'baf36946-b436-4de5-a3a8-87952630e086',
    postLogoutRedirectUri: 'https://mba-dev.azure.chevron.com',
    authority: 'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    redirectUri: 'https://mba-dev.azure.chevron.com',
    clientSecret: "OoO8Q~d6MKsKjtzsiDfGCyuHGzd.qBm735rnOduk",
    graphApis: {
      users: 'https://graph.microsoft.com/v1.0/users',
      me: 'https://graph.microsoft.com/v1.0/me'
    },
    endpoints: {
      'https://chevron.onmicrosoft.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26'
        : 'https://chevron.onmicrosoft.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26'
    },
    scope:{
      basePath:'https://mba-api-dev.azure.chevron.com/',
      userImpersonation :'https://mba-dev.azure.chevron.com/user_impersonation',
    }
  },
  webApiUrl: 'https://mba-api-dev.azure.chevron.com/',
  cacheLocation: 'localStorage', 
  // REDIRECT_URI: 'http://localhost:4200',
  // POST_LOGOUT_REDIRECT: 'http://localhost:4200',
  // cacheLocation: 'localStorage',
  //redirectUri:window.location.origin+'/#/search-material',
  //expireOffsetSeconds:3550,
  navigateToLoginRequestUrl: false,
};
