import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, map, of } from 'rxjs';
//import { AngularReduxRequestOptions } from '../redux/angular-redux-request.options';
import { LoadingService } from './loading.service';
import { HttpClient, HttpHandler, HttpEvent, HttpEventType, HttpRequest, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Paginator } from '../models/base.model';


@Injectable()
export class HttpService {

    constructor(
        //backend: XHRBackend,
        //defaultOptions: AngularReduxRequestOptions,
        //handler : HttpHandler,
        //private loaderService: LoadingService,
        private httpClient: HttpClient
    ) {
        //super(handler);
        //super(backend,defaultOptions);
    }

    countLoader: number = 0

    // get(url: string): Observable<any> {
    //     //this.showLoader(url);
    //     return this.httpClient.get<any>(this.getFullUrl(url))
    //         .catch(this.onCatch)
    //         .do((res: Response) => {

    //             this.onSuccess(res)
    //         }, (error: any) => {
    //             this.onError(error)
    //         })
    //         .finally(() => {
    //             this.onEnd(url)
    //         });

    //     //return this.sendRequest('GET', url);
    // }

    // get(url: string, responseType: any = 'json'): Observable<any> {
    //     // debugger
    //     //this.showLoader(url);
    //     return this.httpClient.get<any>(this.getFullUrl(url), { responseType: responseType }).pipe(
    //         catchError(error => of(this.onError(error))),
    //         map((data) => this.onSuccess(data)),
    //         finalize(() => this.onEnd(url))
    //         );

    //     //return this.sendRequest('GET', url);
    // }
    get(url: string,responseType: any = 'json'): Observable<any> {
        return this.httpClient.get<any>(this.getFullUrl(url), { responseType: responseType });
    }
    getWithParams(url: string, params: any, responseType: any = 'json'): Observable<any> {
        // debugger
        //this.showLoader(url);
        return this.httpClient.get<any>(this.getFullUrl(url), { params: params, responseType: responseType })
        .pipe(
            catchError(error => of(this.onError(error))),
            map((data) => this.onSuccess(data)),
            finalize(() => this.onEnd(url))
            );

        //return this.sendRequest('GET', url);
    }

    post(url: string, body: any, options?: any): Observable<any> {

        return this.httpClient.post<any>(this.getFullUrl(url), body, options)
        .pipe(
            catchError(error => of(this.onError(error))),
            map((data:any) => this.onSuccess(data)),
            finalize(() => this.onEnd(url))
            );
    }

    postWithPadginator(url: string, body: any, pagin?: Paginator): Observable<any> {
        return this.httpClient.post<HttpResponse<any>>(this.getFullUrl(url), body,
            {
                headers: new HttpHeaders({ "pagin-data": JSON.stringify(pagin) }),
                observe: 'response'
            }).pipe(
                catchError(error => of(this.onError(error))),
                map((res:any) =>{ 
                    if (pagin) {
                        pagin.TotalRecordCount = Number(res.headers.get('X-Paging-TotalRecordCount'));
                    }
                    this.onSuccess(res)
                }),
                finalize(() => this.onEnd(url))
            )
    }


    put(url: string, body: any): Observable<any> {
        return this.httpClient.put<any>(this.getFullUrl(url), body)
        .pipe(
            catchError(error => of(this.onError(error))),
            map((data:any) => this.onSuccess(data)),
            finalize(() => this.onEnd(url))
            );
    }

    delete(url: string): Observable<any> {
        return this.httpClient.delete<any>(this.getFullUrl(url))
        .pipe(
            catchError(error => of(this.onError(error))),
            map((data:Response) => this.onSuccess(data)),
            finalize(() => this.onEnd(url))
            );
    }

    deleteWithParam(url: string, param: HttpParams): Observable<any> {
        return this.httpClient.delete<any>(this.getFullUrl(url), {
            params: param
        }).pipe(
            catchError(error => of(this.onError(error))),
            map((data:any) => this.onSuccess(data)),
            finalize(() => this.onEnd(url))
            );
    }

    // private requestOptions(options?: RequestOptionsArgs): RequestOptionsArgs {
    //     if (options == null) {
    //         options = new AngularReduxRequestOptions();
    //     }
    //     if (options.headers == null) {
    //         options.headers = new Headers();
    //     }
    //     return options;
    // }

    private getFullUrl(url: string): string {
        return `${environment.webApiUrl}` + url
    }

    // private onCatch(error: any, caught: Observable<any>): Observable<any> {
    //     // console.log('*** ----------catch : error =>', error)
    //     return Observable(error);
    // }

    private onSuccess(res: Response): void {
        // console.log('Request successful');
    }

    private onError(res: Response): void {
        // console.log('Error, status code: ' + res.status);
    }

    private onEnd(url: string): void {
        //this.hideLoader(url);
    }

    /*
        private showLoader(url: string): void {
            this.countLoader++
            if (this.countLoader === 1) {
                this.loaderService.showLoading()
               console.log('-------- show_Loader => ', this.countLoader + " : " + url)
            } else if (this.countLoader < 0) {
                this.countLoader = 0
               console.log('show_Loader => ', this.countLoader + " : " + url)
            } else {
               console.log('-------- show_Loader => ', this.countLoader + " : " + url)
            }
        }
    
        private hideLoader(url: string): void {
            if (this.countLoader > 0) {
                this.countLoader--
                if (this.countLoader === 0) {
                    this.loaderService.hideLoading()
                   console.log('-------- hide_Loader => ', this.countLoader + ' : ' + url)
                } else {
                   console.log('hide_Loader => ', this.countLoader + ' : ' + url)
                }
            } else {
                this.countLoader = 0
               console.log('** hide_Loader => ', this.countLoader + ' : ' + url)
            } 
        }*/
}