import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { GraphService } from './ms-graph-client.service';
import { ProfileType } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    public loginDisplay = new Subject<boolean | null>();
    public profile = new Subject<ProfileType | null>(); 
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private garphService: GraphService,
        private msalService: MsalService
    ){
       // this.getUserProfile();
        //this.setLoginDisplay();
        
    }
    
    
    getUserProfile(): void {
        this.garphService.getAdUserProfile().subscribe((profile: ProfileType) =>{this.profile.next(profile), console.log('profile2::', profile)});
    }
    
    // getUserProfileDetails():Observable<ProfileType>{
    //    return this.garphService.getAdUserProfile();
    // }
    setLoginDisplay(): void {
        this.loginDisplay.next(this.msalService.instance.getAllAccounts().length > 0);
    }
    isAuthenticated(){
        if(this.msalService.instance.getAllAccounts()){
            return true;
        }
        else{
            return false;
        }
    }

    clearCache(){
        localStorage.clear();
    }

    accessToken(){
        return this.msalService.instance.getAllAccounts();
    }
    login() {
        this.msalService.loginRedirect();
      }

    logout(): void {
        if(this.msalGuardConfig.interactionType == InteractionType.Popup){
            this.msalService.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
              });
        } else {
            this.msalService.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
        this.loginDisplay.next(null);
    }

    acceptDisclaimer() {
     
    }
    
    
}