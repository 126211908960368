export class UserDTO {
  Id: number;
  constructor(
    public CAI: string,
    public Fullname: string,
    public Email: string,
    public JobTitle: string,
    public Department: string,
    public Company: string,
    public FirstName: string,
    public LastName: string,
    public UserPrincipalName: string) { }

}

export type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  displayName?: string,
  id?: string,
  mail?:string,
  mobilePhone?:string
};

export type GraphResponseType = {
  value: ProfileType[];
}