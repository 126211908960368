import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filesize',pure: false })

export class FileSizeStringPipe implements PipeTransform {
    transform(fileSize: number) {
        if (fileSize == 0) return '0 Bytes';
        const k = 1024,
            decimals = 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(fileSize) / Math.log(k));
        return parseFloat((fileSize / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
    }
  }

