import { Component, OnInit, ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription,combineLatest } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Services

//import { Logger } from '../../services/logger.service';
import { AuthService } from '../../services/auth.service';
import { PeopleFinderController } from './people-finder.controller';
import { PeopleFinderService } from './people-finder.service';
import { Person } from './people-finder.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserDTO } from '../../models/user.model';
import { BaseComponent } from '../base-component';
import { NgxBootstrapConfig } from '../../ngx-bootstrap-config/ngx-bootstrap-config';

// Models
@Component({
  selector: 'user-lov',
  styleUrls: ['./people-finder.component.css'],
  template: ` <div class="input-group" style="width: 100%;">
    <input [ngClass]="{'IsRequired': !isFound &&  required == true }"  #nameInput="ngModel" [required]="required" [readonly]="isFound || disableForm" type="text" class="form-control"  [(ngModel)]='searchKeyword' (keyup.enter)="validateUser()" >
    <span *ngIf="!isFound" class="input-group-addon pointer" [ngClass]="{'disabled-step-btn': disableForm}" (click)="validateUser()" >
      <span class="glyphicon glyphicon-search dark-blue pointer" style="font-size:20px;" > </span> 
    </span>
    <span *ngIf="isFound" class="input-group-addon pointer" [ngClass]="{'disabled-step-btn': disableForm}"  (click)="Clear()" >
      <span class="glyphicon glyphicon-remove dark-blue pointer" style="font-size:20px;" > </span> 
    </span>
  </div>
`
})
export class UserLookupComponent extends BaseComponent implements OnInit {

  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  isFound: boolean = false;
  @Input() disableForm: boolean = false;
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Input() searchKeyword: string;
  @Input() required: boolean = false;
  @Output() clear: EventEmitter<any> = new EventEmitter();
  constructor(ngxBootstrapConfig: NgxBootstrapConfig, authService: AuthService,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private peopleFinderService: PeopleFinderService
  ) {
    super(ngxBootstrapConfig, authService);

  }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.changeDetection.detectChanges();
  }

  ngOnChanges() {
    this.isFound = false;
    if (this.searchKeyword != null && this.searchKeyword != '' && this.searchKeyword != undefined)
      this.isFound = true;
  }

  @Input() set KeywordsearchNow(key) {
    if (key && this.searchKeyword != key) {
      this.searchKeyword = key;
      this.validateUser();
    }
  }



  validateUser() {
    this.peopleFinderService.searchUsers(this.searchKeyword).subscribe(data => {
      if (data && data.length == 1) {
        this.searchKeyword = data[0].FirstName + " " + data[0].LastName;
        this.isFound = true;
        this.select.emit(data[0]);
      } else {
        this.openPeopleFinder();
      }
    });
  }
  openPeopleFinder() {

    if (this.modalRef) {
      this.modalRef.hide();
    }

    const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());


    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        let person = this.modalRef.content;
        if (person && person.LoginId && person.LoginId != '' && person.LoginId != undefined) {
          this.searchKeyword = (person.FirstName + " " + person.LastName)
          this.isFound = true;
          this.select.emit(person);
          this.modalRef.content = null;
        }
      })
    );
    this.subscriptions.push(_combine);

    const initialState = {
      searchKeyword: this.searchKeyword
    };

    this.modalRef = this.modalService.show(PeopleFinderComponent,
      Object.assign({ initialState },
        { animated: true, class: 'modal-center modal-lg', ignoreBackdropClick: true })
    );
  }

  Clear() {
    this.isFound = false;
    this.searchKeyword = null;
    this.clear.emit();

  }
}

@Component({
  selector: 'app-people-finder',
  templateUrl: './people-finder.component.html',
  styleUrls: ['./people-finder.component.css']
})
export class PeopleFinderComponent implements OnInit {

  searchKeyword: string;
  persons: UserDTO[] = [];
  showNotFound: boolean = false

  constructor(
    private authService: AuthService,
    private peopleFinderService: PeopleFinderService,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    if (this.searchKeyword) {
      this.searchByPeople();
    }
  }



  searchByPeople() {
    this.showNotFound = true;

    this.peopleFinderService.searchUsers(this.searchKeyword).subscribe(data => {
      if (data && data.length == 1) {
        this.bsModalRef.content = data[0];
        this.bsModalRef.hide();
        this.showNotFound = false
      } else if (data && data.length > 0) {
        this.persons = data
        this.showNotFound = false
      }

    }, err => {
      this.persons = []
      console.log('search by people : Error =>', err);
    })
  }

  selectPerson(person) {
    console.log(person);
    this.bsModalRef.content = person;
    this.bsModalRef.hide();
  }


}
