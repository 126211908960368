import { Injectable } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';


@Injectable()
export class NgxBootstrapConfig {

  colorTheme = 'theme-dark-blue';
  dateInputFormat = 'DD-MMM-YYYY';
  rangeInputFormat = 'DD-MMM-YYYY';
  rangeSeparator = ' to '
  bsConfig: Partial<BsDatepickerConfig>;

  constructor() {
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: this.dateInputFormat, rangeInputFormat: this.rangeInputFormat, rangeSeparator: this.rangeSeparator, showWeekNumbers: false });
  }
}