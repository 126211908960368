export class Program {
    Id: number;
    ProgramCode: string;
    ProgramSequence: number;
    ProgramName: string;
    IsActive: boolean;
    Menu: Menu[] = [];
    constructor() {
        this.Menu = []
    }
}

export class Menu {
    Id: number;
    ProgramId: number;
    MenuSequence: number;
    MenuName: string;
    MenuPath: string;
    IsActive: boolean;
    IsReadOnly: boolean;
    constructor() { }
}

export class MenuBarApp {
    Program: Program[];
    LinkHelp: string;
    LinkFeedback: string;
    constructor() {
        this.Program = []
    };
}
export class UserLogin{
    Active:boolean;
    Email:string;
    FirstName:string;
    Id:number;
    LastName:string;
    LoginId:string;
    SupplierId:number;
    JobTitle:string;
    Department:string;
    FreightforwarderID:number;
    WarehouseCode:string;
    CompanyCode:string;
    FullName:string;
    Role:Array<string>=[];
    IsAdmin:boolean;
    IsBuyer:boolean;
    IsTA:boolean;
    IsWarehouse:boolean;
    UserPermission:MenuBarApp;
    token:string;
}
