import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized-application',
  templateUrl: './unauthorized-application.component.html',
  styleUrls: ['./unauthorized-application.component.css']
})
export class UnauthorizedApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
