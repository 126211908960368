import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../../services/http.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileBtnService {

  constructor(private httpService: HttpService) { }
  
  public DownloadFileTemplate(FileName: string){
    return this.httpService.post('api/'+ FileName +'/DownloadFileTemplate', FileName, { responseType: "blob" });
  }

}
