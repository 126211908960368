import { Component, OnInit } from '@angular/core';
import { Alert, AlertType } from '../../models/alert.model';
import { AlertService } from '../../services/alert.service';

@Component({
  // moduleId: module.id,
  selector: 'alert',
  templateUrl: 'alert-shim.component.html',
  styleUrls: ['alert-shim.component.css']
})

export class AlertComponent {
  alerts: Alert[] = [];

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array
      this.alerts.push(alert);



      switch (alert.type) {
        case AlertType.Success:
          setTimeout(() => {
            this.removeAlert(alert);
          }, 2000);
        // case AlertType.Error:
        case AlertType.Info:
        case AlertType.Warning:
          setTimeout(() => {
            this.removeAlert(alert);
          }, 3000);
      }

      if (alert.type === AlertType.Error) {
        console.log('save error to LOG : ', alert.message);
      }
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'vv-alert vv-alert-success';
      case AlertType.Error:
        return 'vv-alert vv-alert-danger';
      case AlertType.Info:
        return 'vv-alert vv-alert-info';
      case AlertType.Warning:
        return 'vv-alert vv-alert-warning';
    }
  }
}