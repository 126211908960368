// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-invalid:not(div),
.ng-valid:not(div).IsRequired {
    border-left: 5px solid #a94442;
}

.ng-valid[required]:not(.IsRequired),
.ng-valid.required:not(.IsRequired) {
    border-left: 5px solid #42A948;
}

.disabled-step {
    background-color: #eee;
    pointer-events: none;
}

.disabled-step-btn {
    opacity: .5;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/people-finder/people-finder.component.css"],"names":[],"mappings":"AAAA;;IAEI,8BAA8B;AAClC;;AAEA;;IAEI,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".ng-invalid:not(div),\n.ng-valid:not(div).IsRequired {\n    border-left: 5px solid #a94442;\n}\n\n.ng-valid[required]:not(.IsRequired),\n.ng-valid.required:not(.IsRequired) {\n    border-left: 5px solid #42A948;\n}\n\n.disabled-step {\n    background-color: #eee;\n    pointer-events: none;\n}\n\n.disabled-step-btn {\n    opacity: .5;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
