import { Component } from '@angular/core';
import { Alert } from '../../models/alert.model';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'loading',
  template: `
    <div class="container-fluid width-1200 vv-loading-background" [hidden]="!loading">
      <div id="vv-loader"></div>
    </div>
  `,
  styleUrls: ['loading-page.component.css']
})

export class LoadingComponent {
  alerts: Alert[] = [];
  loading: boolean = false;

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.getAlertLoad().subscribe((isShow: boolean) => {
      setTimeout(()=>{this.loading = isShow},10);
    });
    
  }
}