import {NgForm} from "@angular/forms";
import { CanDeactivateComponent } from './can-deactivate.component';

export abstract class FormCanDeactivate extends CanDeactivateComponent {

 abstract get form():NgForm;
 abstract get isForcedClosed():boolean;
 canDeactivate():boolean{
        if(!this.form) return true;
        if(this.isForcedClosed) return true;
        return this.form.submitted || !this.form.dirty || this.form.pristine;
  }
}