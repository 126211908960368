import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wordbreak',pure: false })

export class WordBreakPipe implements PipeTransform {
    transform(text: string) {
        if (text) return text.replace(/\n/g, '<br />');

    }
  }

