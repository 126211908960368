import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import '../../rxjs/rxjs-operators';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { Person, GraphAttributes } from './people-finder.model';

import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import * as MicrosoftGraphClient from "@microsoft/microsoft-graph-client/lib/src"
import { StorageService } from '../../services/storage.service';
import { HttpService } from '../../services/http.service';
import { LoadingService } from '../../services/loading.service';
import { UserDTO } from '../../models/user.model';
import { MsalService } from '@azure/msal-angular';

const Graph_URI = environment.azureAd.graphApis.me;

@Injectable()
export class PeopleFinderService {



  client: MicrosoftGraphClient.Client;

  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private storageService: StorageService,
    private http: HttpClient,
    private httpService: HttpService,
    // private http: HttpService
    private loadingService: LoadingService
  ) {


  }
  getADALService(graphToken) {
    let err;
    var client = MicrosoftGraphClient.Client.init({
      //defaultVersion:'beta',
      authProvider: (done) => {
        done(err, graphToken); //first parameter takes an error if you can't get an access token

      }
    });
    return client;
  }
  public getClient(callBack) {
    var that = this;
    let graphToken = this.authService.accessToken();
    callBack(graphToken);


  }




  /*
  * Call the graph search REST API
  */
  searchByPeople(keyword: string): Promise<UserDTO[]> {
    this.loadingService.showLoading()
    var that = this;
    return new Promise<any>((resolve, rej) => {
      that.getClient((token) => {
        var client = that.getADALService(token);
        client.api('/users')
          .version("beta")
          .filter(`startswith(${GraphAttributes.GivenName}, '${keyword}') or startswith(${GraphAttributes.Surname}, '${keyword}') or startswith(${GraphAttributes.Cai}, '${keyword}') or startswith(${GraphAttributes.JobTitle}, '${keyword}') or startswith(${GraphAttributes.staticUserPrincipalName}, '${keyword}') or startswith(${GraphAttributes.Mail}, '${keyword}')`)
          //.filter(`startswith(${GraphAttributes.GivenName}, '${keyword}') or startswith(${GraphAttributes.Surname}, '${keyword}') or startswith(${GraphAttributes.staticUserPrincipalName}, '${keyword}')`)
          .get((err, res) => {
            if (err) {
              // console.log('searchByPeople',err);
              this.loadingService.hideLoading()
              return;
            } else {
              let persons: Person[];
              let users: UserDTO[] = [];
              persons = res ? res.value : [];
              // console.log('res.value', res.value);
              // console.log('persons',persons);
              // console.log(' res.value',res.value);
              persons.forEach(person => {
                // console.log('person.displayName',person.displayName);
                users.push(new UserDTO(
                  person.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11,
                  person.displayName,
                  person.mail,
                  person.jobTitle,
                  person.department,
                  person.companyName,
                  person.givenName,
                  person.surname,
                  person.userPrincipalName));
              });
              that.loadingService.hideLoading()
              resolve(users);
            }

          });
      })
      /*
            let client = this.getClient();
            client.api('/users')
              .version("beta")
              .filter(`startswith(${GraphAttributes.GivenName}, '${keyword}') or startswith(${GraphAttributes.Surname}, '${keyword}') or startswith(${GraphAttributes.Cai}, '${keyword}') or startswith(${GraphAttributes.JobTitle}, '${keyword}') or startswith(${GraphAttributes.staticUserPrincipalName}, '${keyword}')`)
              //.filter(`startswith(${GraphAttributes.GivenName}, '${keyword}') or startswith(${GraphAttributes.Surname}, '${keyword}') or startswith(${GraphAttributes.staticUserPrincipalName}, '${keyword}')`)
              .get((err, res) => {
                if (err) {
                  // console.log('searchByPeople',err);
                  this.loadingService.hideLoading()
                  return;
                } else {
                  let persons: Person[];
                  let users: UserDTO[] = [];
                  persons = res ? res.value : [];
                  console.log('res.value', res.value);
                  // console.log('persons',persons);
                  // console.log(' res.value',res.value);
                  persons.forEach(person => {
                    // console.log('person.displayName',person.displayName);
                    users.push(new UserDTO(
                      person.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11,
                      person.displayName,
                      person.mail,
                      person.jobTitle,
                      person.department,
                      person.companyName,
                      person.givenName,
                      person.surname));
                  });
                  this.loadingService.hideLoading()
                  resolve(users);
                }
      
              })
              */
    });
  }



  searchUsers(keyword: string): Observable<UserDTO[]> {
    return this.httpService.get('/api/user/' + keyword)
  }
  searchEmployeeLDAP(keyword: string): Observable<UserDTO[]> {
    return this.httpService.get('/api/user/' + keyword)
  }

}

