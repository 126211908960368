import { Menu } from './app.model';
import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppService } from "./app.service";

@Injectable()
export class AppAuthorizedGuard implements CanActivate {

  constructor(private appService: AppService, private router: Router) {
  }

  /**
   *  Protects the routes to reach with authentication
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
  }

}