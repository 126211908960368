import { Component, OnInit } from '@angular/core';
import { NgxBootstrapConfig } from '../ngx-bootstrap-config/ngx-bootstrap-config';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AuthService } from '../services/auth.service';
import { FormCanDeactivate } from './can-deactivate/form-can-deactivate.component';
import { NgForm } from '@angular/forms';



export class BaseComponent extends FormCanDeactivate {
  form: NgForm;
  isForcedClosed: boolean = false;
  maxRow = 0;
  bsConfig: Partial<BsDatepickerConfig>;
  constructor(public ngxBootstrapConfig: NgxBootstrapConfig,
    public authService: AuthService,) {
    super();

    this.bsConfig = ngxBootstrapConfig.bsConfig;
  }

  indexShift: IndexShift[] = [];


  focusInput(rowIdx: number[], colIdx: number, data: any) {
    let text = rowIdx.length == 1 ? rowIdx[0] : rowIdx[0] + '' + rowIdx[1];
    var inputName = `input_${text}_${colIdx}`;
    var control = $(`#${inputName}`);
    //console.log('try forcu: ',control);

    if (control.length > 0) {

      control.focus();
      control.trigger("click");

      return true;
    } else {
      return false;
    }

  }

  shiftFocusDown(rowIdx: number[], colIdx: number, data: any) {

    if (rowIdx.length == 2) {
      let tmp = this.indexShift.find(x => x.row1 == rowIdx[0] && x.row2 == rowIdx[1]);
      let index = this.indexShift.indexOf(tmp);
      if ((index + 1) < this.indexShift.length) {
        this.focusInput([this.indexShift[(index + 1)].row1, this.indexShift[(index + 1)].row2], colIdx, data);
      }
    }
    else if (rowIdx.length == 1) {
      let tmp = this.indexShift.find(x => x.row1 == rowIdx[0]);
      let index = this.indexShift.indexOf(tmp);
      if ((index + 1) < this.indexShift.length) {
        this.focusInput([this.indexShift[(index + 1)].row1], colIdx, data);
      }
    }

  }
  shiftFocusUp(rowIdx: number[], colIdx: number, data: any) {

    if (rowIdx.length == 2) {
      let tmp = this.indexShift.find(x => x.row1 == rowIdx[0] && x.row2 == rowIdx[1]);
      let index = this.indexShift.indexOf(tmp);
      if ((index - 1) >= 0) {
        this.focusInput([this.indexShift[(index - 1)].row1, this.indexShift[(index - 1)].row2], colIdx, data);
      }
    }
    else if (rowIdx.length == 1) {
      let tmp = this.indexShift.find(x => x.row1 == rowIdx[0]);
      let index = this.indexShift.indexOf(tmp);
      if ((index - 1) >= 0) {
        this.focusInput([this.indexShift[(index - 1)].row1], colIdx, data);
      }
    }

  }
  shiftFocusLeft(rowIdx: number[], colIdx: number, data: any) {
    // don't need edge check bc findIndex returns -1 if none found or no items, so that corrects us back to start col automatically
    this.focusInput(rowIdx, colIdx, data);
  }
  shiftFocusRight(rowIdx: number[], colIdx: number, data: any) {
    // don't need edge check bc findIndex returns -1 if none found or out of range, so that corrects us back to start col automatically
    this.focusInput(rowIdx, colIdx, data);
  }




}

interface IndexShift {
  row1: number;
  row2: number;
}