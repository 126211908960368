import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ErrorLog } from '../models/error-log.model';

@Injectable()
export class LoggerService {

    constructor(private httpService : HttpService) {
        
    }


    log(errorLog: ErrorLog) {
        console.log('errorLog',errorLog);
       return this.httpService.post(`/api/applog`, errorLog);
    }
}